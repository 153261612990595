import React from 'react';
import PaymentOptions from '../components/PaymentOptions';

function PaymentPage() {
    return (
        <div className="mt-4 mx-4">
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <PaymentOptions />
                </div>
            </div>
        </div>
    );
}

export default PaymentPage;
