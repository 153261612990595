import React from 'react';
import Settings from '../components/Settings';

function SettingsPage() {
    return (
        <div className="mt-4 mx-4">
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <Settings />
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;
