import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; // Assuming you have this setup
import { BASE_URL } from '../../config';


import { convertHeight, convertWeight } from '../../utils/unitConversions';
import PasswordChangeModal from '../PasswordChangeModal';
import ActionButton from './../utils/ActionButton';

function ProfileDetails() {
    const { user, profile } = useContext(AppContext); // Get user and profile data from context
    const navigate = useNavigate(); // For routing to the edit profile page
    const [showModal, setShowModal] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    console.log(user);
    console.log(profile);

    const handleEditProfile = () => {
        navigate('/edit-profile');
    };

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match");
            return;
        }

        setLoading(true);
        setErrorMessage(""); // Clear any previous error messages

        try {
            const response = await axiosInstance.put(`${BASE_URL}/api/auth/change-password/`, {
                old_password: oldPassword,
                new_password: newPassword,
            });

            if (response.status === 200) {
                alert('Password changed successfully');
                setShowModal(false); // Close the modal on success
            }
        } catch (error) {
            if (error.response) {
                // Server-side validation error
                const errorData = error.response.data;
                if (error.response.status === 400) {
                    // Check for the specific error messages returned from the backend
                    if (errorData.error) {
                        setErrorMessage(errorData.error);
                    } else if (errorData.old_password) {
                        setErrorMessage(errorData.old_password[0]);
                    } else if (errorData.new_password) {
                        setErrorMessage(errorData.new_password[0]);
                    } else {
                        setErrorMessage('Failed to change password. Please check the provided data.');
                    }
                } else {
                    // General error for other status codes
                    setErrorMessage('An unexpected error occurred. Please try again.');
                }
            } else {
                // Network or other errors
                setErrorMessage('Failed to change password. Please try again.');
            }
        }

        setLoading(false);
    };

    return (
        <div className='p-6 bg-[#2D2D2D] shadow-lg rounded-md'>
            <h2 className="text-2xl font-semibold text-white mb-6">My Profile</h2>

            {/* Flex container that changes layout based on screen size */}
            <div className="flex flex-col lg:flex-row">
                {/* Profile Picture - moves to the top on small screens */}
                <div className="flex flex-col items-center justify-center p-5 pt-0 w-full lg:w-1/4">
                    <img
                        src={profile.profile_picture ? `${BASE_URL}/${profile.profile_picture}` : 'https://via.placeholder.com/150'}
                        alt="Profile"
                        className="w-32 h-32 rounded-md object-cover mb-4"
                    />
                    <p className="text-white text-sm">{profile.user_type === 'trainer' ? 'Trainer' : 'Single User'}</p>
                </div>

                {/* Profile Information */}
                <div className="flex-1 mt-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-gray-400 text-sm mb-2">First Name:</label>
                            <p className="text-white">{user.first_name}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Last Name:</label>
                            <p className="text-white">{user.last_name}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Username:</label>
                            <p className="text-white">{user.username}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Email:</label>
                            <p className="text-white">{user.email}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Height:</label>
                            <p className="text-white">
                                {convertHeight(profile.height, profile.height_unit)} {profile.height_unit}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Weight:</label>
                            <p className="text-white">
                                {convertWeight(profile.weight, profile.weight_unit)} {profile.weight_unit}
                            </p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Address:</label>
                            <p className="text-white">{profile.address}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Postcode:</label>
                            <p className="text-white">{profile.postcode}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Town:</label>
                            <p className="text-white">{profile.town}</p>
                        </div>

                        <div>
                            <label className="block text-gray-400 text-sm mb-2">Country:</label>
                            <p className="text-white">{profile.country}</p>
                        </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex justify-end mt-6 space-x-4">
                        <ActionButton
                            onClick={() => setShowModal(true)}
                            label="Change Password"
                        />

                        <ActionButton
                            onClick={handleEditProfile}
                            label="Edit Profile"
                        />
                    </div>
                </div>
            </div>

            {showModal && (
                <PasswordChangeModal
                    oldPassword={oldPassword}
                    setOldPassword={setOldPassword}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    errorMessage={errorMessage}
                    handlePasswordChange={handlePasswordChange}
                    loading={loading}
                    setShowModal={setShowModal}
                />
            )}
        </div>

    );
}

export default ProfileDetails;
