import React from 'react';
import personRunningIcon from '../../static/person-running-solid.svg';
import leftIcon from '../../static/ar-left.svg';
import { FRONTEND_BASE_URL } from '../../config';

const Metrics = ({ isVisible, toggleLeftSidebar, totalSessionTime, stepCount, stepLength, isSmallScreen }) => {
  return (
    <div
        className={`${
            isVisible
            ? isSmallScreen
                ? 'absolute w-64 z-40 h-full' // Reduce width on small screens when sidebar is visible
                : 'w-2/12'
            : isSmallScreen
            ? 'absolute w-16 z-40 h-full' // Reduce collapsed width on small screens
            : 'w-0.5/12'
        } transition-all duration-300`}
    >
      <div className="menu_item menu_left align-self-start col-2 order-1">
        <div className="flex justify-between mb-5 pt-2.5">
          	{isVisible && <div className="left text-white">All Metrics</div>}
			<div className="title_right">
					<img
					className={`h-6 w-6 head_icon cursor-pointer transform transition-transform duration-300 ${
						isVisible ? '' : 'rotate-180'
					}`}
					src={`${FRONTEND_BASE_URL}${leftIcon}`}
					alt="left arrow icon"
					onClick={toggleLeftSidebar}
					/>
			</div>
        </div>
        {isVisible && (
			<div className="metrics">
				<div className="metric">
					<div className="value">{`${Math.floor(totalSessionTime / 60)}:${('0' + (totalSessionTime % 60)).slice(-2)}`}</div>
					<div className="label">Total Session Time</div>
					<div className="description">Step frequency</div>
					<div className="icon_div">
						<img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt="person running icon" />
					</div>
				</div>
				<div className="metric">
					<div className="value">{stepCount}</div>
					<div className="label">Steps</div>
					<div className="description">Step frequency</div>
					<div className="icon_div">
						<img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt="person running icon" />
					</div>
				</div>
				<div className="metric">
					<div className="value">{stepLength} m</div>
					<div className="label">Step Length</div>
					<div className="description">Step frequency</div>
					<div className="icon_div">
						<img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt="person running icon" />
					</div>
				</div>
			</div>
        )}
      </div>
    </div>
  );
};

export default Metrics;
