import React, { useState, useEffect } from 'react';
import InputFieldLight from '../utils/InputFieldLight';


const PayPal = ({ paypal_email, handleInputChange, serverError }) => {
    const [emailError, setEmailError] = useState('');

    // Email validation function
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Handle input change with validation
    const handleEmailChange = (e) => {
        const email = e.target.value;
        handleInputChange(e); // Update parent state
        if (!validateEmail(email)) {
            setEmailError('Enter a valid email address.');
        } else {
            setEmailError(''); // Clear error when valid
        }
    };

    // Check if server error exists and display it
    useEffect(() => {
        if (serverError) {
            setEmailError(serverError); // Display server error if passed from backend
        }
    }, [serverError]);
    return (
        <InputFieldLight
            type="email"
            name="paypal_email"
            value={paypal_email}
            onChange={handleEmailChange}
            placeholder="Enter PayPal email"
            required
            className={`${emailError ? 'border-red-500' : ''}`}  // Conditionally apply error style
            errorMessage={emailError}  // Display the error message below the field
        />
    );
};

export default PayPal;
