import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import Metrics from '../components/video-analysis/Metrics';
import VideoAnalysisStats from '../components/video-analysis/VideoAnalysisStats';
import VideoContent from '../components/video-analysis/VideoContent';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StepsChart from '../components/video-analysis/StepsChart';
import { BASE_URL } from '../config';
import { Pose } from '@mediapipe/pose';
import { drawLandmarks, drawConnectors } from '@mediapipe/drawing_utils';


function VideoAnalysis() {
    const { videoId } = useParams();

    const [activeTab, setActiveTab] = useState(0);

    const [accordionItems, setAccordionItems] = useState([
        { id: 1, isOpen: true, percentage: 100, name: "Head position", status: 'Great' },
        { id: 2, isOpen: false, percentage: 100, name: "Back position", status: 'Great' },
        { id: 3, isOpen: false, percentage: 70, name: "Foot Landing", status: 'Good' },
        { id: 4, isOpen: false, percentage: 70, name: "Ideal position", status: 'Good' },
        { id: 5, isOpen: false, percentage: 10, name: "Low heel kick", status: 'Bad' },
        { id: 6, isOpen: false, percentage: 10, name: "Overflexion", status: 'Bad' }
    ]);
    const [videoSrc, setVideoSrc] = useState(null);
    const [totalSessionTime, setTotalSessionTime] = useState(0);
    const [stepCount, setStepCount] = useState(0);
    const [stepLength, setStrideLength] = useState(0);
    const [isLeftSidebarVisible, setIsLeftSidebarVisible] = useState(true);
    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1300); // Track small screen size

    const [poseLandmarker, setPoseLandmarker] = useState(null);
    const [isSteppingRight, setIsSteppingRight] = useState(false);
    const [isSteppingLeft, setIsSteppingLeft] = useState(false);
    const [maxStepLength, setMaxStepLength] = useState(0);


    //values for dsiaplying which foot part strikes the ground first
    const [firstContact, setFirstContact] = useState("");
    // To track foot ground contact time
    const [FootContactTime, setFootContactTime] = useState(null);

    // for dsiaplaying the angles of the foot at time of striking the grounf
    const [frontFootAngle, setFrontFootAngle] = useState(null);
    const [backFootAngle, setBackFootAngle] = useState(null);


    const [minZValueL, setMinZValueL] = useState(Infinity); // To track minimum z value of the left heel
    const [minZValueR, setMinZValueR] = useState(Infinity); // To track minimum z value of the right heel


    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const knownHeightMeters = 1.75;
    let maxHeight = 0

    let FootContactTimeStart = null;
    let rightFootContactTimeStart = null;
    let scalingFactor = 1;
    let ankleAtContact = null
    let rightAnkleAtContact = null


    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const response = await axiosInstance.get(`${BASE_URL}/api/videos/video/${videoId}/`);
                const videoData = response.data;
                const filePath = videoData.file.startsWith('/') ? videoData.file.slice(1) : videoData.file;
                const fullVideoUrl = `${BASE_URL}/api/videos/protected-videos/${filePath}`;
                const videoStream = await axiosInstance.get(fullVideoUrl, { responseType: 'blob' });
                const videoBlob = new Blob([videoStream.data], { type: 'video/mp4' });
                setVideoSrc(URL.createObjectURL(videoBlob));
            } catch (error) {
                console.error('Error fetching video data:', error);
            }
        };
        fetchVideoDetails();
    }, [videoId]);


    useEffect(() => {
        const initializePose = async () => {
            const pose = new Pose({
                locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
            });

            pose.setOptions({
                modelComplexity: 1,
                smoothLandmarks: true,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5,
            });

            pose.onResults(handleResults);

            setPoseLandmarker(pose);
        };

        initializePose();
    }, []);



    const handleResults = (results) => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        const context = canvas.getContext('2d');

        if (!context || !results.poseLandmarks) return;

        // Sync canvas size with video size
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.clearRect(0, 0, canvas.width, canvas.height);

        drawLandmarks(context, results.poseLandmarks, {
            radius: 4,
            color: 'rgb(255, 255, 255)',
        });

        drawConnectors(context, results.poseLandmarks, Pose.POSE_CONNECTIONS, {
            color: 'rgb(0, 255, 0)',
            lineWidth: 4,
        });
        const nose =  results.poseLandmarks[0]
        const leftAnkle = results.poseLandmarks[27];
        const rightAnkle = results.poseLandmarks[28];
        const leftHip = results.poseLandmarks[23];
        const rightHip = results.poseLandmarks[24];
        const leftKnee = results.poseLandmarks[25];
        const rightKnee = results.poseLandmarks[26];
        const leftHeel = results.poseLandmarks[29];
        const rightHeel = results.poseLandmarks[30];
        const leftFootIndex = results.poseLandmarks[31];
        const rightFootIndex = results.poseLandmarks[32];


        // Calculate midfoot as 40% from foot_index to heel
        // const t = 0.4
        // const midfoot_x_left = leftFootIndex.x + t * (leftHeel.x - leftFootIndex.x)
        // const midfoot_y_left = leftFootIndex.y + t * (leftHeel.y - leftFootIndex.y)
        // const midfoot_z_left = leftFootIndex.z + t * (leftHeel.z - leftFootIndex.z)

        // const midfoot_y_right = rightFootIndex.y + t * (rightHeel.y - rightFootIndex.y)
        // const midfoot_x_right = rightFootIndex.x + t * (rightHeel.x - rightFootIndex.x)
        // const midfoot_z_right = rightFootIndex.z + t * (rightHeel.z - rightFootIndex.z)


        // const rHiptoAnkle = getAngle(rightHip, rightKnee, rightAnkle);
        // const lHiptoAnkle = getAngle(leftHip, leftKnee, leftAnkle);

        // getting max mp heigth for calculating pixelsPerMeter
        const currentHeight = Math.abs(nose.y - Math.min(rightAnkle.y, leftAnkle.y));

        // settting heigth to max as it changes while moving
        if (currentHeight > maxHeight) {
            maxHeight = currentHeight;
            console.log(`New max height: ${maxHeight}`);
        }


        const angleKneeL = getAngle(results.poseLandmarks[27], results.poseLandmarks[25], results.poseLandmarks[23]); // Left leg
        const angleKneeR = getAngle(results.poseLandmarks[28], results.poseLandmarks[26], results.poseLandmarks[24]); // Right leg



        //analysis conditions for the left leg
        if (angleKneeL > 160 && angleKneeR < 120 && !isSteppingLeft && isSteppingRight) {
            const stepLength = Math.abs(leftFootIndex.x - rightFootIndex.x) * scalingFactor*2;
            setStrideLength(stepLength);

            setStepCount((prev) => prev + 1);
            setIsSteppingLeft(true);
            setIsSteppingRight(false);


            //this resets z value for other step as terrian migth change
            setMinZValueR(Infinity)

            const currentZValueL = leftHeel.z;
            if (currentZValueL < minZValueL) {
                ankleAtContact = leftAnkle
                setMinZValueL(currentZValueL);
                // setFootContactTime(Date.now()); // staarts timer
                FootContactTimeStart = Date.now();
                //angles at contact for front foot and back foot
                const angleF = getAngle(leftAnkle, leftKnee,leftHip)
                setFrontFootAngle(angleF);
                const angleB = getAngle(rightAnkle,rightKnee,rightHip)
                setBackFootAngle(angleB);

          }
          detectFootStrike(leftHeel, leftFootIndex, "left");

        }
        else if (angleKneeR > 160 && angleKneeL < 120 && !isSteppingRight && isSteppingLeft) {
            // Right-side stepping logic
            const stepLength = Math.abs(rightFootIndex.x - leftFootIndex.x) * scalingFactor * 2;
            setStrideLength(stepLength);

            setStepCount((prev) => prev + 1);
            setIsSteppingRight(true);
            setIsSteppingLeft(false);

            // Reset left foot's z value as terrain might change
            setMinZValueL(Infinity);

            const currentZValueR = rightHeel.z;
            if (currentZValueR < minZValueR) {
                setMinZValueR(currentZValueR);


                rightAnkleAtContact = rightAnkle;
                // setFootContactTime(Date.now()); // Start timer for right foot
                rightFootContactTimeStart = Date.now();
                // Calculate front foot and back foot angles at contact
                const angleF = getAngle(rightAnkle, rightKnee, rightHip);
                setFrontFootAngle(angleF);
                const angleB = getAngle(leftAnkle, leftKnee, leftHip);
                setBackFootAngle(angleB);
            }

            // Detect foot strike for the right foot
            detectFootStrike(rightHeel, rightFootIndex, "right");
        }
        // Ground contact time calculation
        if (FootContactTimeStart !== null && ankleAtContact !== null) {
            if (Math.abs(leftFootIndex.z) < Math.abs(ankleAtContact.z)) {
                const gct =
                (performance.now() - FootContactTimeStart) / 1000;
                console.log(`Ground Contact Time: ${gct.toFixed(2)} seconds`);
                setFootContactTime(gct.toFixed(2));

                // Reset contact times and ankle data
                FootContactTimeStart = null;
                ankleAtContact = null;
            }
        }
        if (rightFootContactTimeStart !== null && rightAnkleAtContact !== null) {
            if (Math.abs(rightFootIndex.z) < Math.abs(rightAnkleAtContact.z)) {
                const gct =
                (performance.now() - rightFootContactTimeStart) /
                1000; // Convert to seconds
                console.log(`Ground Contact Time: ${gct.toFixed(2)} seconds`);
                setFootContactTime(gct.toFixed(2));

                // Reset contact times and ankle data
                rightFootContactTimeStart = null;
                rightAnkleAtContact = null;
            }
        }

        // checkContactPoint(leftHeel, leftFootIndex, midfoot_z_left, rightHeel, rightFootIndex, midfoot_z_right);

        // handleStepCounting(rHiptoAnkle, lHiptoAnkle, rightAnkle, leftAnkle, results.poseLandmarks);
    };

    const detectFootStrike = (heel, footIndex, side) => {
        const t = 0.4;
        const midfootZ = footIndex.z + t * (heel.z - footIndex.z);
        if (Math.abs(heel.z) < Math.abs(footIndex.z) && Math.abs(heel.z) < Math.abs(midfootZ)) {
          setFirstContact(`${side} heel strikes first`);
        } else if (Math.abs(footIndex.z) < Math.abs(heel.z) && Math.abs(footIndex.z) < Math.abs(midfootZ)) {
          setFirstContact(`${side} foot index strikes first`);
        } else {
          setFirstContact(`${side} midfoot strikes first`);
        }
    };

    useEffect(() => {
        if (poseLandmarker && videoRef.current) {
            const video = videoRef.current;

            const processVideo = async () => {
                if (video.readyState >= 2 && !video.paused && !video.ended) {
                    await poseLandmarker.send({ image: video });
                    setTotalSessionTime(prevTime => prevTime + 1);
                }
                requestAnimationFrame(processVideo);
            };

            video.addEventListener('play', processVideo);

            return () => {
                video.removeEventListener('play', processVideo);
            };
        }
    }, [poseLandmarker]);


    const handleTabChange = (index) => setActiveTab(index);
    const handleToggleAccordion = (id) => {
        setAccordionItems(accordionItems.map((item) => ({
            ...item,
            isOpen: item.id === id ? !item.isOpen : false
        })));
    };
    const toggleLeftSidebar = () => setIsLeftSidebarVisible(!isLeftSidebarVisible);
    const toggleRightSidebar = () => setIsRightSidebarVisible(!isRightSidebarVisible);

    // This useEffect handles the screen resizing for showing/hiding sidebars
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1300) {
                setIsSmallScreen(true);
                setIsLeftSidebarVisible(false);
                setIsRightSidebarVisible(false);
            } else {
                setIsSmallScreen(false);
                setIsLeftSidebarVisible(true);
                setIsRightSidebarVisible(true);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div>
            <Header />
            <hr />
            <main className='pt-14'>
                <div className="h-full flex relative">
                    <Metrics
                        isVisible={isLeftSidebarVisible}
                        toggleLeftSidebar={toggleLeftSidebar}
                        totalSessionTime={totalSessionTime}
                        stepCount={stepCount}
                        stepLength={stepLength}
                        isSmallScreen={isSmallScreen} // Pass the screen size info to LeftSidebar
                    />
                    <VideoContent
                        videoSrc={videoSrc}
                        videoRef={videoRef}
                        canvasRef={canvasRef}
                        playbackSpeed={1.0}
                        StepsChart={StepsChart}
                        isSmallScreen={isSmallScreen}
                    />
                    <VideoAnalysisStats
                        isSmallScreen={isSmallScreen}
                        isVisible={isRightSidebarVisible}
                        toggleRightSidebar={toggleRightSidebar}
                        accordionItems={accordionItems}
                        handleToggleAccordion={handleToggleAccordion}
                        tabs={[
                            { name: 'Experiments', content: 'This is the content of tab 1' },
                            { name: 'Exercise', content: 'This is the content of tab 2' },
                        ]}
                        activeTab={activeTab}
                        handleTabChange={handleTabChange}
                    />
                </div>
            </main>
            <Footer />
        </div>
    );
}


function getAngle(start, middle, end) {
    const radians = Math.atan2(end.y - middle.y, end.x - middle.x) - Math.atan2(start.y - middle.y, start.x - middle.x);
    let angle = Math.abs(radians * 180.0 / Math.PI);
    if (angle > 180.0) {
        angle = 360.0 - angle;
    }
    return angle;
}

export default VideoAnalysis;
