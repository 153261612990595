// FolderItem.js
import React from 'react';
import { formatDistanceToNow } from 'date-fns';

const FolderItem = ({ folder, activeFolderMenu, toggleFolderMenu, handleMenuItemClick, onViewFolder }) => {
    return (
        <div
            key={folder.id}
            className="relative bg-[#2D2D2D] shadow-md w-full sm:w-[calc(50%-1rem)] md:w-[calc(33%-1rem)] lg:w-[calc(25%-1rem)] xl:w-[calc(20%-1rem)] cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => onViewFolder(folder.id)}
            style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}
        >
            <div className="bg-[#2D2D2D] h-40 flex items-center justify-center">
                <i className="fas fa-folder text-[#4CAF50] text-6xl"></i>
            </div>
            <div className="p-4 bg-[#2D2D2D] shadow-md" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
                <span className="text-sm font-medium text-white block">{folder.name}</span>
                <span className="text-xs text-gray-400 mt-1 block">
                    Edited {folder.updated_at ? formatDistanceToNow(new Date(folder.updated_at), { addSuffix: true }) : 'some time ago'}
                </span>
            </div>
            {/* Three-dot menu icon with hover effect */}
            <div className="absolute bottom-2 right-2">
                <button
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering folder card click
                        toggleFolderMenu(folder.id);
                    }}
                    className="text-white p-2 px-3 rounded-full hover:bg-[#212121] focus:outline-none"
                    style={{ transition: 'background-color 0.2s ease' }}
                >
                    <i className="fas fa-ellipsis-v"></i>
                </button>
            </div>
            {/* Dropdown menu */}
            {activeFolderMenu === folder.id && (
                <div className="absolute bottom-10 right-0 bg-white text-black shadow-lg rounded w-48">
                    <ul className="p-2">
                        <li
                            className="hover:bg-gray-100 p-2 cursor-pointer flex items-center text-[#2D2D2D] w-full"
                            onClick={(e) => handleMenuItemClick('rename', folder.id, 'folder', e)}
                        >
                            <i className="fas fa-edit mr-2"></i>
                            <span className="w-full">Rename</span>
                        </li>
                        <li
                            className="hover:bg-gray-100 p-2 cursor-pointer flex items-center text-[#2D2D2D] w-full"
                            onClick={(e) => handleMenuItemClick('move_to_trash', folder.id, 'folder', e)}
                        >
                            <i className="fas fa-trash mr-2"></i>
                            <span className="w-full">Move to trash</span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FolderItem;
