import React, { useState } from 'react';
import ActionButton from './utils/ActionButton';  // Assuming you have an ActionButton component
import axiosInstance from '../axiosInstance';  // Your axios instance
import { BASE_URL } from '../config';  // Your config file
import InputFieldLight from '../components/utils/InputFieldLight';

const RunnerForm = ({ formData, setFormData, isEditing, editingRunnerId, setRunners, handleCloseModal }) => {
    const [errors, setErrors] = useState({});  // Track errors returned from server

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Reset errors
        setErrors({});

        try {
            let response;
            if (isEditing) {
                // If editing, send a PUT request to update the runner
                response = await axiosInstance.put(`${BASE_URL}/api/runners/${editingRunnerId}/`, formData);
                // Update the runner in the runners list
                setRunners((prevRunners) =>
                    prevRunners.map((runner) => (runner.id === editingRunnerId ? response.data : runner))
                );
            } else {
                // If adding a new runner, send a POST request
                response = await axiosInstance.post(`${BASE_URL}/api/runners/`, formData);
                // Add the new runner to the runners list
                setRunners((prevRunners) => [...prevRunners, response.data]);
            }

            // Close the modal after successful submission
            handleCloseModal();
        } catch (error) {
            if (error.response && error.response.data) {
                // Server-side validation errors
                setErrors(error.response.data);  // Assuming the server returns errors in a structured way
            } else {
                // Handle other errors (network issues, etc.)
                console.error('Error saving runner:', error);
            }
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
                <label className="block text-sm mb-2">Name</label>
                <InputFieldLight
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter runner's name"
                    required
                    autoFocus
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>

            <div className="mb-4">
                <label className="block text-sm mb-2">Height (cm)</label>
                <InputFieldLight
                    name="height"
                    type="number"
                    value={formData.height}
                    onChange={handleChange}
                    placeholder="Enter runner's height"
                    required
                />
                {errors.height && <p className="text-red-500 text-xs mt-1">{errors.height}</p>}
            </div>

            <div className="mb-4">
                <label className="block text-sm mb-2">Weight (kg)</label>
                <InputFieldLight
                    name="weight"
                    type="number"
                    value={formData.weight}
                    onChange={handleChange}
                    placeholder="Enter runner's weight"
                    required
                />
                {errors.weight && <p className="text-red-500 text-xs mt-1">{errors.weight}</p>}
            </div>


            <div className="flex justify-end">
                <ActionButton
                    type="submit"
                    label={isEditing ? 'Save Changes' : 'Submit'}
                />
            </div>
        </form>
    );
};

export default RunnerForm;
