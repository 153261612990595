import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MediaExplorer from '../components/MediaExplorer';
import FolderModal from '../components/FolderModal';
import Modal from '../components/Modal';
import Breadcrumb from '../components/utils/Breadcrumb';
import { BASE_URL } from '../config';
import axiosInstance from '../axiosInstance';


function Home() {
    const { folderId } = useParams(); // Get folderId from the URL parameters
    const navigate = useNavigate(); // To programmatically navigate
	const location = useLocation();
    const { setFolderId } = useContext(AppContext);
    const [folders, setFolders] = useState([]); // State to hold current folder's subfolder data
    const [videos, setVideos] = useState([]);  // State to hold videos data

    const [currentParent, setCurrentParent] = useState(folderId || null); // Initialize currentParent with URL parameter
    const [folderPath, setFolderPath] = useState([{ id: null, name: 'My Dashboard' }]);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false); // State for folder modal
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (folderId) {
            setCurrentParent(folderId);  // Set current folder from URL
        } else {
            setCurrentParent(null);
            setFolderPath([{ id: null, name: 'My Dashboard' }]);
        }
    }, [folderId]);

    useEffect(() => {
        setFolderId(currentParent);

        const fetchData = async () => {
            setLoading(true);
            try {
                const [foldersResponse, videosResponse] = await Promise.all([fetchFolders(), fetchVideos()]);
                setFolders(foldersResponse);
                setVideos(videosResponse);

                rebuildBreadcrumb(currentParent, foldersResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [currentParent]);

    const fetchFolders = async () => {
        try {
            const url = currentParent === null
                ? `${BASE_URL}/api/folders/parent/`
                : `${BASE_URL}/api/folders/parent/${currentParent}/`;

            const response = await axiosInstance.get(url);
            const fetchedFolders = response.data;

            return fetchedFolders;
        } catch (error) {
            console.error('Error fetching folders:', error);
            return [];
        }
    };

    const fetchVideos = async () => {
        try {
            const url = currentParent === null
                ? `${BASE_URL}/api/videos/folder/root/`
                : `${BASE_URL}/api/videos/folder/${currentParent}/`;

            const response = await axiosInstance.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching videos:', error);
            return [];
        }
    };

    const handleCreateFolder = async (folderData) => {
        try {
            const response = await axiosInstance.post(`${BASE_URL}/api/folders/create/`, {
                ...folderData,
                parent: currentParent
            });

            if (response.status === 201) {
                const newFolder = response.data;
                setFolders((prevFolders) => [...prevFolders, newFolder]);
                setIsFolderModalOpen(false);
            } else {
                throw new Error('Failed to create folder');
            }
        } catch (error) {
            console.error('Error creating folder:', error);

            // Check if there are validation errors from the server
            if (error.response && error.response.data && error.response.data.name) {
                throw new Error(error.response.data.name); // Throw the validation error message
            }
            throw new Error('An error occurred while creating the folder'); // General error
        }
    };

    const handleViewFolder = (folderId) => {
        navigate(`/folder/${folderId}`);
        // Set the clicked folder as the new parent to fetch its children
        setCurrentParent(folderId);
    };


    // Rebuild the breadcrumb when navigating to a folder through URL
    const rebuildBreadcrumb = async (folderId) => {
        const path = []; // Start with Dashboard

        let currentFolderId = folderId;

        // Fetch folders from API if not available in the local state
        let currentFolder = folders.find(folder => folder.id === currentFolderId);

        // If the folder is not found locally, fetch it from the server
        while (currentFolderId) {
            if (!currentFolder) {
                // Fetch the current folder data by its ID
                try {
                    const response = await axiosInstance.get(`${BASE_URL}/api/folders/${currentFolderId}/`);
                    currentFolder = response.data;
                } catch (error) {
                    console.error(`Error fetching folder ${currentFolderId}:`, error);
                    break;
                }
            }

            // Add the current folder to the breadcrumb path
            path.push({ id: currentFolder.id, name: currentFolder.name });

            // Move to the parent folder
            currentFolderId = currentFolder.parent;
            currentFolder = folders.find(folder => folder.id === currentFolderId);
        }
        path.reverse();
        // Always add 'My Dashboard' as the first item
        path.unshift({ id: null, name: 'My Dashboard' });

        setFolderPath(path); // Reverse to ensure the order is correct
    };



    const handleNavigateBreadcrumb = (folderId) => {
        // Navigate to a specific folder from the breadcrumb
        setCurrentParent(folderId);
        const folderIndex = folderPath.findIndex(folder => folder.id === folderId);
        setFolderPath(folderPath.slice(0, folderIndex + 1)); // Update breadcrumb path
        navigate(folderId === null ? '/home' : `/folder/${folderId}`);

    };

    return (
        <div>
            <Header onCreateFolder={() => setIsFolderModalOpen(true)} />
            <main className='px-5 pt-14'>
                <div className="h-full">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <svg className="animate-spin h-8 w-8 text-[#4caf50]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                            </svg>
                        </div>
                    ) : (
                        <>
                            <Breadcrumb folderPath={folderPath} onNavigate={handleNavigateBreadcrumb} />
                            <MediaExplorer
                                folders={folders}
                                videos={videos}
                                onViewFolder={handleViewFolder}
                                setFolders={setFolders}
                                setVideos={setVideos} />
                        </>
                    )}
                </div>
				{(location.pathname === '/home' || location.pathname.startsWith('/folder/')) && (
                    <button
                        onClick={() => setIsFolderModalOpen(true)}
                        className="fixed bottom-5 right-5 bg-[#4caf50] text-white shadow-lg hover:bg-[#43a047] transition-all duration-300 flex justify-center items-center"
                        style={{ width: '50px', height: '50px', fontSize: '24px', borderRadius: '10px' }}
                    >+
                    </button>
				)}

            </main>
            <Footer />
            <Modal isOpen={isFolderModalOpen} onClose={() => setIsFolderModalOpen(false)}>
                <FolderModal onCreate={handleCreateFolder} onClose={() => setIsFolderModalOpen(false)} />
            </Modal>
        </div>
    );
}

export default Home;
