import React, { useState, useEffect, useRef } from 'react';

function FolderModal({ onClose, onCreate, folder = null }) {
    const [name, setName] = useState(folder ? folder.name : ''); // Pre-fill the name if folder is passed
    const [error, setError] = useState(''); // State to track and display errors
    const inputRef = useRef(null); // Create a ref for the input field

    // Focus the input field when the modal opens
    useEffect(() => {
        inputRef.current.focus(); // Automatically focus the input field
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reset error before submitting

        try {
            if (folder) {
                await onCreate(folder.id, name); // Pass the folder ID and the updated name
            } else {
                // Otherwise, it's a new folder creation
                await onCreate({ name });
            }
            setName(''); // Clear the input field after submission
        } catch (serverError) {
            setError(serverError.message);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">
                {folder ? 'Rename Folder' : 'Create New Folder'}
            </h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Folder Name</label>
                    <input
                        type="text"
                        value={name}
                        ref={inputRef} // Attach the ref to the input field
                        onChange={(e) => setName(e.target.value)}
                        className={`mt-1 block w-full border border-gray-300 focus:border-[#4caf50] focus:outline-none shadow-sm p-1 ${
                            error ? 'border-red-500' : ''
                        }`} // Add red border on error
                        required
                    />
                    {error && (
                        <p className="text-red-500 text-sm mt-1">{error}</p> // Display error message
                    )}
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-gray-500 text-white px-3 py-1 border border-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={`${
                            folder ? 'bg-[#4caf50]' : 'bg-[#4caf50]'
                        } text-white px-3 py-1 ml-2 border ${
                            folder ? 'border-[#4caf50]' : 'border-[#4caf50]'
                        }`}
                    >
                        {folder ? 'Update' : 'Create'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default FolderModal;
