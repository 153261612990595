import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL } from '../config';
import axios from 'axios';
import LoadingSpinner from '../components/utils/LoadingSpinner';

import InputFieldLight from '../components/utils/InputFieldLight';
import ActionButton from '../components/utils/ActionButton';

import DividerWithText from '../components/utils/DividerWithText';
import GoogleLoginButton from '../components/GoogleLoginButton';

function Register() {
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isTrainer, setIsTrainer] = useState(false); // New state for trainer checkbox
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false); // Add loading state
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true); // Start loading
		try {
			const response = await axios.post(
				`${BASE_URL}/api/auth/register/`,
				{
					username,
					email,
					password,
					is_trainer: isTrainer, // Send is_trainer field to the backend
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 201) { // Assuming a 201 User is created
				navigate('/verify-email'); // Redirect to Verify Email page
			} else {
				setErrorMessage(response.data.error || 'Registration failed');
			}
		} catch (error) {
			if (error.response && error.response.data && error.response.data.error) {
				setErrorMessage(error.response.data.error);
			} else {
				setErrorMessage('Error registering user');
			}
			console.error('Error registering user', error);
		} finally {
			setLoading(false); // Stop loading
		}
	};

	const handleCheckboxChange = (e) => {
		setIsTrainer(e.target.checked); // Update isTrainer state based on checkbox value
	};
	const handleGoogleLogin = () => {
		// Google login logic here
	};

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
		<form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-8 rounded shadow-md space-y-4">
			<h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Register</h2>
			{errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

			<InputFieldLight
				type="text"
				placeholder="Username"
				value={username}
				onChange={(e) => setUsername(e.target.value)}
				required
				className="mb-4"
				autoFocus
			/>
			<InputFieldLight
				type="email"
				placeholder="Email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				className="mb-4"
				required
			/>
			<InputFieldLight
				type="password"
				placeholder="Password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				required
			/>

			<div className="flex items-center mb-4">
			<input
				type="checkbox"
				id="isTrainer"
				checked={isTrainer}
				onChange={handleCheckboxChange}
				className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
			/>
			<label htmlFor="isTrainer" className="ml-2 text-sm text-gray-700">
				Are you Trainer ?
			</label>
			</div>


			<ActionButton
				onClick={handleSubmit} // or leave empty since it's a submit button
				label={loading ? <LoadingSpinner /> : 'Register'}
				type="submit"
				customStyles={`w-full flex items-center justify-center text-white py-2 focus:outline-none ${loading ? 'cursor-not-allowed' : ''}`}
				disabled={loading}
			/>


			<p className="text-center mt-4">
			Already have an account?{' '}
			<Link to="/login" className="text-blue-500 hover:underline">
				Login
			</Link>
			</p>

			<div className="text-center mt-4">
				<DividerWithText text="OR" />
				<GoogleLoginButton label="signup_with" />
			</div>

		</form>
		</div>
);
}

export default Register;
