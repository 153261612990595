import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppContext';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLinks';

function MySideBar({ isDark }) {
    const [isHovered, setIsHovered] = useState(false);
    const location = useLocation();
    const { profile } = useContext(AppContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            className={`fixed flex flex-col top-14 left-0 h-full text-white border-r-[1px] border-gray-600 z-10 transition-all duration-[800ms] ease-in-out
            ${isMobile ? (isHovered ? "w-64" : "w-13") : "w-64"}
            bg-[#2D2D2D] dark:bg-[#2D2D2D]`}
            onMouseEnter={() => isMobile && setIsHovered(true)}
            onMouseLeave={() => isMobile && setIsHovered(false)}
        >
            <div className="overflow-y-auto flex flex-col justify-between flex-grow">
                <ul className="flex flex-col py-4 space-y-1">
                    <li className="px-5 hidden md:block">
                        <div className="flex flex-row items-center h-8">
                            <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
                                Main
                            </div>
                        </div>
                    </li>
                    <SidebarLink
                        to="/my-account"
                        iconClass="fas fa-home"
                        label="My Account"
                        isMobile={isMobile}
                        isHovered={isHovered}
                        isActive={location.pathname === '/my-account'}
                    />
                    {profile?.user_type === 'trainer' && (
                        <SidebarLink
                            to="/runners"
                            iconClass="fas fa-running"
                            label="Runners"
                            isMobile={isMobile}
                            isHovered={isHovered}
                            isActive={location.pathname === '/runners'}
                        />
                    )}
                    <li className="px-5 hidden md:block">
                        <div className="flex flex-row items-center mt-5 h-8">
                            <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
                                Settings
                            </div>
                        </div>
                    </li>
                    <SidebarLink
                        to="/payment"
                        iconClass="fas fa-credit-card"
                        label="Payment"
                        isMobile={isMobile}
                        isHovered={isHovered}
                        isActive={location.pathname === '/payment'}
                    />
                    <SidebarLink
                        to="/user-profile"
                        iconClass="fas fa-user"
                        label="Profile"
                        isMobile={isMobile}
                        isHovered={isHovered}
                        isActive={location.pathname === '/user-profile'}
                    />
                    <SidebarLink
                        to="/settings"
                        iconClass="fas fa-cog"
                        label="Settings"
                        isMobile={isMobile}
                        isHovered={isHovered}
                        isActive={location.pathname === '/settings'}
                    />
                </ul>
            </div>
        </div>
    );
}

export default MySideBar;
