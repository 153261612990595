import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL } from '../config';
import { AppContext } from '../AppContext';
import axios from 'axios';
import LoadingSpinner from '../components/utils/LoadingSpinner';
import InputFieldLight from '../components/utils/InputFieldLight';

import ActionButton from '../components/utils/ActionButton';

import DividerWithText from '../components/utils/DividerWithText';

import GoogleLoginButton from '../components/GoogleLoginButton';


function Login() {
	const { login } = useContext(AppContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false); // Add loading state
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true); // Start loading

		try {
		const response = await axios.post(`${BASE_URL}/api/auth/login/`, {
			email,
			password,
		}, {
			headers: {
			'Content-Type': 'application/json',
			},
		});

		if (response.status === 200) {
			const data = response.data;

			// Save refresh and access tokens, user, and profile in local storage and context
			login(data.tokens, data.user, data.profile);

			navigate('/home'); // Redirect to the home page
		} else {
			setErrorMessage(response.data.error || 'Login failed');
		}
		} catch (error) {
			if (error.response) {
				// Server responded with a status other than 200 range
				setErrorMessage(error.response.data.error || 'Login failed');
			} else {
				setErrorMessage('Error logging in');
			}
			console.error('Error logging in', error.response);
		} finally {
			setLoading(false); // Stop loading
		}
	};
	const handleGoogleLogin = () => {
		// Google login logic here
	};
	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="w-full max-w-md bg-white p-8 rounded shadow-md">
				<h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Login</h2>
				{errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
				<form onSubmit={handleSubmit} className="space-y-4">
				<InputFieldLight
					type="text"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					autoFocus
					required
				/>
				<InputFieldLight
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
				<div className="text-right" style={{ margin: 0 }}>
					<Link to="/password-reset" className="text-sm text-blue-500 hover:underline">
					Forgot Password?
					</Link>
				</div>
				<ActionButton
					onClick={handleSubmit} // or leave empty since it's a submit button
					label={loading ? <LoadingSpinner /> : 'Login'}
					type="submit"
					customStyles={`w-full flex items-center justify-center text-white py-2 focus:outline-none ${loading ? 'cursor-not-allowed' : ''}`}
					disabled={loading}
				/>

				</form>
				<p className="text-center mt-4">
					Create an account?{' '}
				<Link to="/register" className="text-blue-500 hover:underline">
					Register
				</Link>
				</p>

				<div className="text-center mt-4">
					<DividerWithText text="OR" />
					<GoogleLoginButton label="signin_with" />
				</div>
			</div>
		</div>
		);
	}

export default Login;
