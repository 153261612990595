import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import axios from 'axios';

import ActionButton from '../components/utils/ActionButton';
import LoadingSpinner from '../components/utils/LoadingSpinner';
import InputFieldLight from '../components/utils/InputFieldLight';

function PasswordResetConfirm() {
	const { uidb64, token } = useParams();
	const navigate = useNavigate();
	const [newPassword, setNewPassword] = useState(''); // Backend expects 'new_password'
	const [confirmPassword, setConfirmPassword] = useState(''); // Backend expects 'confirm_password'
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);  // Show loading spinner

		// Check if passwords match on frontend before submitting
		if (newPassword !== confirmPassword) {
			setError('Passwords do not match');
			setIsLoading(false);  // Hide loading spinner
			return;
		}

		try {
			const response = await axios.post(`${BASE_URL}/api/auth/password-reset-confirm/${uidb64}/${token}/`,
			{
				new_password: newPassword,  // Send as 'new_password'
				confirm_password: confirmPassword  // Send as 'confirm_password'
			},
			{
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.status === 200) {
				// Navigate to login page on successful password reset
				navigate('/login');
			} else {
				setError(response.data.error || 'Password reset failed');
			}
		} catch (error) {
			if (error.response) {
				// The request was made, and the server responded with a status code that falls out of the range of 2xx
				setError(error.response.data.error || 'Password reset failed');
			} else {
				// Something happened in setting up the request that triggered an Error
				setError('An error occurred. Please try again later.');
				console.error('Error resetting password:', error.message);
			}
		} finally {
			setIsLoading(false);  // Hide loading spinner in all cases
		}
	};

	return (
		<div className="flex justify-center items-center min-h-screen bg-gray-100">
			<div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
				<h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">Reset Password</h2>
				{error && <p className="text-red-500 text-sm mb-4">{error}</p>}
				<form onSubmit={handleSubmit}>
					<div className="mb-4">
						<InputFieldLight
							type="password"
							placeholder="New Password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}  // Set 'newPassword'
							required
							autoFocus
						/>
					</div>

					<div className="mb-6">
						<InputFieldLight
							type="password"
							placeholder="Confirm New Password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}  // Set 'confirmPassword'
							required
						/>
					</div>

					<div className="flex items-center justify-between">
						<ActionButton
							type="submit"
							label={isLoading ? <LoadingSpinner /> : 'Reset Password'}
							onClick={handleSubmit} // Handle form submission
							customStyles={`w-full font-bold py-2 px-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
							disabled={isLoading}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

export default PasswordResetConfirm;
