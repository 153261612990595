import React from 'react';
import ProfileDetails from '../components/account/ProfileDetails';

function UserProfilePage() {
    return (
        <div className="mt-4 mx-4">
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
                    <ProfileDetails />
                </div>
            </div>
        </div>
    );
}

export default UserProfilePage;
