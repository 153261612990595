import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // To navigate to other pages
import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';
import ActionButton from '../components/utils/ActionButton';
import LoadingSpinner from '../components/utils/LoadingSpinner';
import Modal from '../components/Modal';
import RunnerForm from '../components/RunnerForm'; // Import the new RunnerForm component

function RunnersListPage() {
	const [runners, setRunners] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false); // New state to check if we're editing
	const [editingRunnerId, setEditingRunnerId] = useState(null); // Track which runner is being edited
	const [loadingRunnerId, setLoadingRunnerId] = useState(null);

	const [formData, setFormData] = useState({
		name: '',
		height: '',
		weight: '',
	});

	const navigate = useNavigate();

	useEffect(() => {
		fetchRunners();
	}, []);

	const fetchRunners = async () => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/api/runners/`);
			setRunners(response.data);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching runners:', error);
			setLoading(false);
		}
	};

	const handleDeleteRunner = async (runnerId) => {
		// Show confirmation dialog
		const confirmed = window.confirm("Are you sure you want to delete this runner? This action cannot be undone.");

		if (!confirmed) {
			return; // If the user cancels the delete action, do nothing
		}

		// Start loading spinner
		setLoadingRunnerId(runnerId);
		try {
			const response = await axiosInstance.delete(`${BASE_URL}/api/runners/${runnerId}/`);
		if (response.status === 204) {
			// Remove the deleted runner from the list
			setRunners((prevRunners) => prevRunners.filter((runner) => runner.id !== runnerId));
		} else {
			throw new Error('Failed to delete runner');
		}
		} catch (error) {
			console.error('Error deleting runner:', error);
		} finally {
			setLoadingRunnerId(null);
		}
	};

	const handleEditRunner = async (runnerId) => {
		try {
			// Find the runner data and populate the form
			const response = await axiosInstance.get(`${BASE_URL}/api/runners/${runnerId}/`);
			const runnerData = response.data;

		// Set form data to the runner's current values
		setFormData({
			name: runnerData.name,
			height: runnerData.height,
			weight: runnerData.weight,
		});

		// Set editing state and runner ID
		setIsEditing(true);
		setEditingRunnerId(runnerId);

		// Open the modal
		setIsModalOpen(true);
		} catch (error) {
			console.error('Error fetching runner data for editing:', error);
		}
	};

	const handleViewFolder = async (runnerId) => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/api/runners/${runnerId}/folders/`);
			const folderData = response.data;
			navigate(`/folder/${folderData.id}`);
		} catch (error) {
			console.error('Error fetching runner folder:', error);
			alert('Could not find the folder for this runner.');
		}
	};

	const handleAddRunner = () => {
		setFormData({ name: '', height: '', weight: '' }); // Reset form for new runner
		setIsEditing(false); // We're not editing, we're adding
		setIsModalOpen(true); // Open modal for adding new runner
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setFormData({ name: '', height: '', weight: '' }); // Reset form when closed
		setEditingRunnerId(null); // Reset the editing runner ID
	};

	// Function to handle form submission (both Add and Edit)
	const handleFormSubmit = async (e) => {
		e.preventDefault();

		try {
			if (isEditing) {
				// If editing, send a PUT request to update the runner
				const response = await axiosInstance.put(`${BASE_URL}/api/runners/${editingRunnerId}/`, formData);
				// Update the runner in the runners list
				setRunners((prevRunners) =>
					prevRunners.map((runner) => (runner.id === editingRunnerId ? response.data : runner))
				);
			} else {
				// If adding a new runner, send a POST request
				const response = await axiosInstance.post(`${BASE_URL}/api/runners/`, formData);
				// Add the new runner to the runners list
				setRunners((prevRunners) => [...prevRunners, response.data]);
			}

			// Close the modal after successful submission
			handleCloseModal();
		} catch (error) {
			// Handle any errors from the API
			console.error('Error saving runner:', error);
		}
	};

	return (
		<div className="mt-4 mx-4">
            <div className="w-full overflow-hidden rounded-lg shadow-xs">
                <div className="w-full overflow-x-auto">
					<div className="bg-[#2D2D2D] p-6 shadow-lg rounded-md">
						<h2 className="text-xl font-semibold text-white mb-4">My Runners</h2>
						<ActionButton
							onClick={handleAddRunner}
							label="Add New Runner"
							iconClass="fas fa-running"
							customStyles="bg-green-500 hover:bg-green-700"
						/>
						{loading ? (
							<div className="flex justify-center items-center mt-4">
								<LoadingSpinner />
							</div>
						) : error ? (
							<div className="text-red-500 text-center">{error}</div>
						) : (
							<>
								{runners.length > 0 ? (
									<table className="w-full mt-3">
										<thead>
											<tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-[#212121]">
												<th className="px-4 py-3">Name</th>
												<th className="px-4 py-3">Height(cm)</th>
												<th className="px-4 py-3">Weight(kg)</th>
												<th className="px-4 py-3">Actions</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-[#212121]">
											{runners.map((runner, index) => (

											<tr className="bg-gray-50 dark:bg-[#212121] text-gray-700 dark:text-gray-400">
												<td className="px-4 py-3">
													<div className="flex items-center text-sm">
														<div>
															<p className="font-semibold">{runner.name}</p>
															{/* <p className="text-xs text-gray-600 dark:text-gray-400">10x Developer</p> */}
														</div>
													</div>
												</td>
												<td className="px-4 py-3 text-sm">{runner.height}</td>
												<td className="px-4 py-3 text-xs">
													{runner.weight}
												</td>
												<td className="px-4 py-3 text-sm">
													<div className="flex space-x-2">
														<button
															onClick={() => handleViewFolder(runner.id)}
															className="text-xs bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded transition-colors duration-300 mr-1"
														>
															View Folders
														</button>
														<ActionButton
															onClick={() => handleEditRunner(runner.id)}
															label="Edit"
															customStyles="bg-yellow-500 hover:bg-yellow-700 text-xs"
														/>
														<ActionButton
															onClick={() => handleDeleteRunner(runner.id)}
															label={loadingRunnerId === runner.id ? <LoadingSpinner size="small" /> : 'Delete'}
															customStyles="bg-red-500 hover:bg-red-700 text-xs"
															disabled={loadingRunnerId === runner.id}  // Disable the button while loading
														/>
													</div>
												</td>
											</tr>
											))}
										</tbody>
                    				</table>
								) : (
									<p className="text-white mt-4">No runners available at this time.</p>
								)}
							</>
						)}

						<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
							<h2 className="text-lg font-semibold mb-4">{isEditing ? 'Edit Runner' : 'Add New Runner'}</h2>
							<RunnerForm
								formData={formData}
								setFormData={setFormData}
								isEditing={isEditing}
								editingRunnerId={editingRunnerId}
								setRunners={setRunners}
								handleCloseModal={handleCloseModal}
							/>
						</Modal>
					</div>
                </div>
            </div>
        </div>

	);
}

export default RunnersListPage;
