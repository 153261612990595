import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import LoadingSpinner from './utils/LoadingSpinner';
import ActionButton from './utils/ActionButton';
import { BASE_URL } from '../config';
import PaymentMethodModal from './PaymentMethodModal';

const PaymentOptions = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(null);  // State to track which method is being deleted

    const [error, setError] = useState(null);
    const [serverError, setServerError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [setAsDefault, setSetAsDefault] = useState(false);

    const [errors, setErrors] = useState({});



    const [newPaymentMethod, setNewPaymentMethod] = useState({
        method_type: 'credit_card',
        name_on_card: '',
        card_number: '',
        expiry_date: '',
        cvv: '',
        bank_account_number: '',
        bank_name: '',
        routing_number: '',
        paypal_email: ''
    });

    useEffect(() => {
        const fetchPaymentMethods = async () => {
            try {
                const response = await axiosInstance.get(`${BASE_URL}/api/payment-methods/`);
                setPaymentMethods(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching payment methods:", err);
                setError("Failed to fetch payment options.");
                setLoading(false);
            }
        };

        fetchPaymentMethods();
    }, []);

    const handleAddPaymentMethod = () => {
        setIsModalOpen(true);  // Open the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);  // Close the modal
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPaymentMethod({
            ...newPaymentMethod,
            [name]: value
        });
    };

    const handleMethodTypeChange = (e) => {
        setNewPaymentMethod({
            method_type: e.target.value,
            card_number: '',
            expiry_date: '',
            cvv: '',
            bank_account_number: '',
            bank_name: '',
            routing_number: '',
            paypal_email: ''
        });
        setErrors({});

    };

    const handleSetAsDefaultChange = () => {
        setSetAsDefault(!setAsDefault);  // Toggle the state
    };

    const handleDeletePaymentMethod = async (methodId) => {
        if (!window.confirm("Are you sure you want to delete this payment method?")) {
            return;  // Exit if the user cancels the action
        }

        setIsDeleting(methodId);  // Set the current method ID as deleting

        try {
            // Make a DELETE request to the backend API to delete the payment method
            const response = await axiosInstance.delete(`${BASE_URL}/api/payment-methods/${methodId}/`);

            if (response.status === 204) {
                // Remove the deleted payment method from the state
                setPaymentMethods(prevMethods => prevMethods.filter(method => method.id !== methodId));
            } else {
                throw new Error("Failed to delete payment method");
            }
        } catch (error) {
            console.error("Error deleting payment method:", error);
            alert("Could not delete the payment method.");
        } finally {
            setIsDeleting(null);  // Reset the deleting state after the operation is complete
        }
    };

    const handleSubmitPaymentMethod = async (e) => {
		e.preventDefault();
		// Filter the data based on the selected payment method
		let paymentData = { method_type: newPaymentMethod.method_type };

        // Clean up card number by removing spaces before sending to backend
        const cleanCardNumber = newPaymentMethod.card_number.replace(/\s+/g, '');


		if (newPaymentMethod.method_type === 'credit_card') {
			paymentData = {
				...paymentData,
                name_on_card: newPaymentMethod.name_on_card,
                card_number: cleanCardNumber,
                expiry_date: newPaymentMethod.expiry_date,
				cvv: newPaymentMethod.cvv
			};
		} else if (newPaymentMethod.method_type === 'paypal') {
			paymentData = { ...paymentData, paypal_email: newPaymentMethod.paypal_email };
		} else if (newPaymentMethod.method_type === 'bank_transfer') {
			paymentData = {
				...paymentData,
				bank_account_number: newPaymentMethod.bank_account_number,
				bank_name: newPaymentMethod.bank_name,
				routing_number: newPaymentMethod.routing_number
			};
		}

		// Add the "set as default" option
		paymentData.is_default = setAsDefault;
		try {
			// Make the API request to add the new payment method
			await axiosInstance.post(`${BASE_URL}/api/add-payment-methods/`, paymentData);

			// Fetch the updated list of payment methods from the server
			const response = await axiosInstance.get(`${BASE_URL}/api/payment-methods/`);

			// Update the payment methods in state with the latest data from the server
			setPaymentMethods(response.data);

			// Reset form values after successful submission
            setNewPaymentMethod({
                method_type: 'credit_card',
                card_number: '',
                expiry_date: '',
                cvv: '',
                bank_account_number: '',
                bank_name: '',
                routing_number: '',
                paypal_email: ''
            });
            setSetAsDefault(false);  // Reset the default checkbox
            setIsModalOpen(false);    // Close the modal after successful submission

		} catch (error) {
            // Capture server errors and set them in state to display in the modal
            if (error.response && error.response.data) {
                const serverErrors = error.response.data;
                setServerError(serverErrors); // Set the error in state
            } else {
                console.error('Error adding payment method:', error);
            }
        }
	};
    // Helper function to format the method_type string
    const formatMethodType = (methodType) => {
        return methodType
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    if (loading) {
        return (
            <div className="bg-[#2D2D2D] p-6 shadow-lg rounded-md">
                <h2 className="text-xl font-semibold text-white">Payment Options</h2>
                <div className="flex justify-center items-center mt-4">
                    <LoadingSpinner />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-[#2D2D2D] p-6 shadow-lg rounded-md">
                <h2 className="text-xl font-semibold text-white">Payment Options</h2>
                <p className="text-red-400 mt-4">{error}</p>
            </div>
        );
    }
    return (
        <div className="bg-[#2D2D2D] p-6 shadow-lg rounded-md">
            <h2 className="text-xl font-semibold text-white">My Payment Options</h2>
            <div className="mt-6">
                <ActionButton
                    label="Add New Payment Method"
                    onClick={handleAddPaymentMethod}  // Open the modal when clicked
                    iconClass="fas fa-plus"
                />
            </div>

            {paymentMethods.length > 0 ? (
                <ul className="mt-4">
                    {paymentMethods.map((method) => (
                        <li key={method.id} className="flex justify-between items-center border-b border-gray-600 py-3">
                            <div className="text-white">
                                <span className="font-semibold">
                                    {formatMethodType(method.method_type)}  {/* Call a function to format the method type */}
                                </span>
                                {method.is_default && <span className="ml-2 text-green-500">(Default)</span>}

                                {method.method_type === 'credit_card' && (
                                    <div className="text-sm text-gray-400">
                                        **** **** **** {method.details.card_number.slice(-4)}
                                        <br />

                                        Name: {method.details.name_on_card}
                                        <br />
                                        Expiry: {method.details.expiry_date}
                                    </div>
                                )}
                                {method.method_type === 'paypal' && (
                                    <div className="text-sm text-gray-400">
                                        PayPal Email: {method.details.paypal_email}
                                    </div>
                                )}
                                {method.method_type === 'bank_transfer' && (
                                    <div className="text-sm text-gray-400">
                                        Bank: {method.details.bank_name} <br />
                                        Account: {method.details.bank_account_number.slice(-4)}
                                        <span className="text-xs text-gray-500 ml-1">(Show last 4 digits)</span>
                                    </div>
                                )}
                            </div>
                            <div className="flex space-x-2">
                                {/* <button
                                    className="text-xs bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700 transition-colors duration-300"
                                    onClick={() => {}}
                                >
                                    Edit
                                </button> */}
                                <ActionButton
                                    onClick={() => handleDeletePaymentMethod(method.id)}
                                    label={isDeleting === method.id ? <LoadingSpinner size="sm" /> : 'Delete'}
                                    customStyles="bg-red-500 hover:bg-red-700 text-xs"
                                    disabled={isDeleting === method.id}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-white mt-4">No payment options available at this time.</p>
            )}

            <PaymentMethodModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                newPaymentMethod={newPaymentMethod}
                handleInputChange={handleInputChange}
                handleMethodTypeChange={handleMethodTypeChange}
                handleSetAsDefaultChange={handleSetAsDefaultChange}
                setAsDefault={setAsDefault}
                handleSubmitPaymentMethod={handleSubmitPaymentMethod}
                serverError={serverError}
                errors={errors}
                setErrors={setErrors}
            />

        </div>
    );
};

export default PaymentOptions;
