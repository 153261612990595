import React from 'react';
import LoadingSpinner from './utils/LoadingSpinner'; // Assuming you already have LoadingSpinner component

import InputFieldLight from './utils/InputFieldLight'
import ActionButton from './utils/ActionButton';

const PasswordChangeModal = ({
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    errorMessage,
    handlePasswordChange,
    loading,
    setShowModal
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-1/3 space-y-4">
        <h2 className="text-xl font-semibold mb-4">Change Password</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

            <InputFieldLight
                type="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="mb-4"
                required
            />
            <InputFieldLight
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mb-4"
                required
            />

            <InputFieldLight
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mb-4"
                required
            />


            <div className="flex justify-end space-x-4">
            <ActionButton
                onClick={() => setShowModal(false)}
                label="Cancel"
                customStyles="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
            />

            <ActionButton
                onClick={handlePasswordChange}
                label={loading ? <LoadingSpinner /> : 'Change Password'}
            disabled={loading}
            />

        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
