import React, {useEffect} from 'react';
import { Routes, Route, Navigate, useLocation  } from 'react-router-dom';
import Home from './pages/Home';
import VideoAnalysis from './pages/VideoAnalysis';
import Login from './pages/Login';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';

import VideoUpload from './pages/VideoUpload';
import PublicRoute from './components/PublicRoute';
import ProtectedRoute from './components/ProtectedRoute';

import './index.css';
import './assets/custom.css';
import Layout from './components/Layout';

import UserProfilePage from './pages/UserProfilePage';
import PaymentPage from './pages/PaymentPage';
import SettingsPage from './pages/SettingsPage';
import EditProfilePage from './pages/EditProfilePage';
import RunnersListPage from './pages/RunnersListPage';
import MyAccount from './pages/MyAccount';

import PasswordResetRequest from './pages/PasswordResetRequest';
import PasswordResetConfirm from './pages/PasswordResetConfirm';


function App() {
    const location = useLocation(); // Get the current route

    useEffect(() => {
        // Define a function to set the document title based on the route
        const setTitle = () => {
            const pathname = location.pathname;

            if (pathname === "/home") {
                document.title = "Home - Fit-Q / Running Analysis";
            } else if (pathname.startsWith("/video-analysis")) {
                document.title = "Video Analysis - Fit-Q / Running Analysis";
            } else if (pathname.startsWith("/folder")) {
                document.title = "Folder - Fit-Q / Running Analysis";
            } else if (pathname === "/login") {
                document.title = "Login - Fit-Q / Running Analysis";
            } else if (pathname === "/register") {
                document.title = "Register - Fit-Q / Running Analysis";
            } else {
                document.title = "Fit-Q / Running Analysis";
            }
        };

        setTitle(); // Set the title when the route changes
    }, [location]); // Depend on location to trigger changes

    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route
                    path="/login"
                    element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                    <PublicRoute>
                        <Register />
                    </PublicRoute>
                    }
                />

                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/password-reset" element={<PasswordResetRequest />} />
                <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm />} />

                {/* Protected Routes */}
                <Route
                    path="/home"
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/my-account"
                    element={
                    <ProtectedRoute>
                        <Layout>
                            <MyAccount />
                        </Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/user-profile"
                    element={
                    <ProtectedRoute>
                        <Layout>
                            <UserProfilePage />
                        </Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                    <ProtectedRoute>
                        <Layout><SettingsPage /></Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/runners"
                    element={
                    <ProtectedRoute>
                        <Layout><RunnersListPage /></Layout>
                    </ProtectedRoute>
                    }
                />

                <Route
                    path="/payment"
                    element={
                    <ProtectedRoute>
                        <Layout><PaymentPage /></Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/edit-profile"
                    element={
                    <ProtectedRoute>
                        <Layout><EditProfilePage /></Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/folder/:folderId"
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/video-upload"
                    element={
                    <ProtectedRoute>
                        <VideoUpload />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/video-analysis/:videoId"
                    element={
                    <ProtectedRoute>
                        <VideoAnalysis />
                    </ProtectedRoute>
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
