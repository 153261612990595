import React, { useState, useRef, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { BASE_URL } from '../config';
import { AppContext } from '../AppContext';
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';

function VideoUpload() {
    const { folderId } = useContext(AppContext);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [isDragging, setIsDragging] = useState(false); // Track drag state
    const fileInputRef = useRef(null); // Use ref to control file input
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [isUploaded, setIsUploaded] = useState(false); // Track if upload is completed

    const navigate = useNavigate();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        handleVideoFile(file);
    };


    const handleVideoFile = (file) => {
        if (file && (file.type === 'video/mp4' || file.type === 'video/quicktime')) {
            // Create a temporary video element to check the duration
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            videoElement.onloadedmetadata = () => {
                const duration = videoElement.duration;

                // Check if the video duration is less than or equal to 120 seconds (2 minutes)
                if (duration <= 120) {
                    setSelectedVideo(file);

                    // Create a URL for the selected video to preview it
                    const videoURL = URL.createObjectURL(file);
                    setPreviewVideo(videoURL);
                    setIsUploaded(false); // Reset upload state
                } else {
                    alert('The video exceeds the maximum allowed duration of 2 minutes.');
                }

                // Clean up the created object URL
                URL.revokeObjectURL(videoElement.src);
            };
        } else {
            alert('Please upload a valid MP4 or MOV file.');
        }
    };


    const handleUpload = async () => {
        if (!selectedVideo) {
            alert('No video selected.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedVideo);
        formData.append('folder', folderId);

        try {
            setIsLoading(true);

            const response = await axiosInstance.post(
                `${BASE_URL}/api/videos/upload/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.status === 201) {
                // Successfully uploaded video
                setSelectedVideo(null);
                setPreviewVideo(null);
                setIsUploaded(true); // Mark as uploaded
                fileInputRef.current.value = ''; // Clear file input

                // Redirect based on folderId
                if (folderId === null) {
                    navigate('/home');
                } else {
                    navigate(`/folder/${folderId}`);
                }
            } else {
                alert(`Failed to upload video: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error uploading video:', error);

            // Check if there's a response from the server
            if (error.response) {
                // Extract and show server-side error message
                const serverMessage = error.response.data.error || error.response.data.message || 'Unknown error occurred';
                alert(`Error uploading video: ${serverMessage}`);
            } else {
                // Show a generic error message
                alert('Error uploading video. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChooseAnotherVideo = () => {
        // Reset the state
        setSelectedVideo(null);
        setPreviewVideo(null);
        fileInputRef.current.value = ''; // Clear the file input value
        setIsUploaded(false); // Reset upload state
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Prevent default behavior (prevents opening the file)
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);

        const file = event.dataTransfer.files[0];
        handleVideoFile(file); // Handle the file upload from drag & drop
    };

    return (
        <div>
            <Header />
            <hr />
            <main className='pt-14'>
                <div
                    className={`h-full px-4 py-6 flex justify-center items-center ${isDragging ? 'bg-[#2D2D2D]' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {/* Video upload container */}
                    <div className="border-dashed border-2 border-gray-400 rounded-lg w-2/3 p-10 text-center">
                        {previewVideo ? (
                            <div className="w-full h-full flex flex-col justify-center items-center overflow-hidden">
                                <video className="mb-4 w-auto h-[480px] max-w-full" controls>
                                    <source src={previewVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        ) : (
                            <>
                                <p className="text-gray-500 text-xl mb-4">Drag and drop your videos here or click to select one</p>
                                <p className="text-gray-500 mb-6">Only MP4 and MOV files are accepted</p>
                            </>
                        )}
                        <input
                            type="file"
                            accept="video/mp4, video/quicktime"
                            id="videoUpload"
                            className="hidden"
                            onChange={handleFileUpload}
                            ref={fileInputRef} // Attach ref to the file input
                        />

                        <div className="flex flex-col items-center">
                            <button
                                className={`bg-[#4caf50] text-white px-4 py-2 rounded mb-2 ${isLoading ? 'cursor-not-allowed' : ''}`}
                                onClick={selectedVideo ? handleUpload : () => fileInputRef.current.click()}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Uploading...' : selectedVideo ? 'Upload Video' : 'Choose a video'}
                            </button>

                            {selectedVideo && !isLoading && (
                                <button
                                    className="text-blue-500 underline"
                                    onClick={handleChooseAnotherVideo}
                                >
                                    Choose Another Video
                                </button>
                            )}

                            {/* Green loading icon during upload */}
                            {isLoading && (
                                <div className="flex items-center mt-4">
                                    <div className="w-8 h-8 rounded-full border-4 border-[#4caf50] border-t-transparent animate-spin"></div>
                                </div>
                            )}

                            {isUploaded && !isLoading && (
                                <div className="flex items-center mt-4">
                                    <div className="w-8 h-8 rounded-full border-4 border-[#4caf50]"></div>
                                    <span className="ml-2 text-[#4caf50]">Upload Complete!</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default VideoUpload;
