import React, { useState, useEffect } from "react";
import Header from '../components/Header';
import MySideBar from "../components/MySideBar";
import Card from "../components/Card";

const MyAccount = () => {

	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 gap-4">
				<Card
					icon={
						<i className="fas fa-running text-blue-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out text-3xl"></i>
					}
					value="1,257"
					label="Runners"
				/>
				<Card
					icon={
						<i className="fas fa-video text-blue-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out text-3xl"></i>
					}
					value="557"
					label="Videos"
				/>
				<Card
					icon={
						<i className="fas fa-credit-card text-blue-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out text-3xl"></i>
					}
					value="11"
					label="Payment methods"
				/>
				<Card
					icon={
						<i className="fas fa-folder text-blue-800 dark:text-gray-800 transform transition-transform duration-500 ease-in-out text-3xl"></i>
					}
					value="150"
					label="Folders"
				/>
			</div>
	);
};

export default MyAccount;
