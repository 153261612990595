import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom'; // For navigation
import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../../config';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import ActionButton from './../utils/ActionButton';
import InputFieldDark from './../utils/InputFieldDark';
import SelectFieldDark from './../utils/SelectFieldDark';


function EditProfile() {
	const { user, profile, setUser, setProfile } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [profileData, setProfileData] = useState({
		first_name: user.first_name,
		last_name: user.last_name,
		email: user.email,
		username: user.username,
		height: profile.height,
		height_unit: profile.height_unit,
		weight: profile.weight,
		weight_unit: profile.weight_unit,
		profile_picture: profile.profile_picture,
		address: profile.address || '',
		postcode: profile.postcode || '',
		town: profile.town || '',
		country: profile.country || '',  // Default country from profile or empty
	});
	const [countries, setCountries] = useState([]);
	const [errors, setErrors] = useState({ user_errors: {}, profile_errors: {} });

	const [previewImage, setPreviewImage] = useState();

	const navigate = useNavigate(); // For navigating after saving the profile

	const handleChange = (e) => {
		setProfileData({
		...profileData,
		[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		const fetchCountries = async () => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/api/countries/`);
			setCountries(response.data);
		} catch (error) {
			console.error('Error fetching countries:', error);
		}
		};

		fetchCountries();
	}, []);

	const handleSave = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append('username', profileData.username);
			formData.append('height', profileData.height);
			formData.append('weight', profileData.weight);
			formData.append('email', profileData.email);
			formData.append('first_name', profileData.first_name);
			formData.append('last_name', profileData.last_name);
			formData.append('address', profileData.address);
			formData.append('postcode', profileData.postcode);
			formData.append('town', profileData.town);
			formData.append('country', profileData.country);

			// Append the profile picture file if it's updated
			if (profileData.profile_picture instanceof File) {
				formData.append('profile_picture', profileData.profile_picture);
			}

			const response = await axiosInstance.put(`${BASE_URL}/api/profile/update-profile/`, formData, {
				headers: {
				'Content-Type': 'multipart/form-data',
				},
			});

			if (response.status === 200) {
				alert('Profile updated successfully');
				// Update the profile state in the context
				const updatedProfilePicture = response.data.profile.profile_picture || profile.profile_picture;
				setUser({
					...user,
					username: profileData.username,
					email: profileData.email,
					first_name: profileData.first_name,
					last_name: profileData.last_name,
				});
				setProfile({
					...profile,
					address: profileData.address,
					postcode: profileData.postcode,
					town: profileData.town,
					country: profileData.country,
					height: profileData.height,
					weight: profileData.weight,
					// Set the URL of the profile picture (from response or existing)
					profile_picture: updatedProfilePicture,
				});

				// Update localStorage for user and profile
				localStorage.setItem(
					'user',
					JSON.stringify({
						...user,
						username: profileData.username,
						email: profileData.email,
						first_name: profileData.first_name,
						last_name: profileData.last_name,
					})
				);
				localStorage.setItem(
					'profile',
					JSON.stringify({
						...profile,
						address: profileData.address,
						postcode: profileData.postcode,
						town: profileData.town,
						country: profileData.country,
						height: profileData.height,
						weight: profileData.weight,
						profile_picture: profileData.profile_picture,
					})
				);
				navigate('/user-profile');
			} else {
				alert('Failed to update profile');
			}
		} catch (error) {
		// Handle server-side validation errors
		if (error.response && error.response.data) {
			setErrors({
				user_errors: error.response.data.user_errors || {},
				profile_errors: error.response.data.profile_errors || {},
			});
		} else {
			alert('An error occurred while updating the profile');
		}
		} finally {
			setLoading(false);
		}
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
		setProfileData({ ...profileData, profile_picture: file }); // Store the file in state
		setPreviewImage(URL.createObjectURL(file)); // For showing the preview
		}
	};

	return (
		<div className="p-6 bg-[#2D2D2D] shadow-lg rounded-md">
			<h2 className="text-2xl font-semibold text-white mb-6">Edit Profile</h2>
			<div className="space-y-6">
				<div className="relative w-32 h-32 mx-auto mb-6">
				<label htmlFor="profile-picture" className="cursor-pointer">
					<img
					src={
						previewImage
						? previewImage
						: profileData.profile_picture
						? `${BASE_URL}/${profileData.profile_picture}`
						: 'https://via.placeholder.com/150'
					}
					alt="Profile"
					className="w-full h-full rounded-full object-cover hover:opacity-70 transition-opacity"
					/>
					<input
					id="profile-picture"
					type="file"
					className="hidden"
					accept="image/*"
					onChange={handleImageChange}
					/>
				</label>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
					{/* First Name */}
					<InputFieldDark
						label="First Name"
						type="text"
						name="first_name"
						value={profileData.first_name}
						onChange={handleChange}
						error={errors.user_errors.first_name}
						errorMessage={errors.user_errors.first_name ? errors.user_errors.first_name[0] : ''}
					/>

					{/* Last Name */}
					<InputFieldDark
						label="Last Name"
						type="text"
						name="last_name"
						value={profileData.last_name}
						onChange={handleChange}
						error={errors.user_errors.last_name}
						errorMessage={errors.user_errors.last_name ? errors.user_errors.last_name[0] : ''}
					/>

					{/* Email */}
					<InputFieldDark
						label="Email"
						type="email"
						name="email"
						value={profileData.email}
						onChange={handleChange}
						error={errors.user_errors.email}
						errorMessage={errors.user_errors.email ? errors.user_errors.email[0] : ''}
					/>

					{/* Username */}
					<InputFieldDark
						label="Username"
						type="text"
						name="username"
						value={profileData.username}
						onChange={handleChange}
						error={errors.user_errors.username}
						errorMessage={errors.user_errors.username ? errors.user_errors.username[0] : ''}
					/>

					{/* Height */}
					<InputFieldDark
						label="Height"
						type="number"
						name="height"
						value={profileData.height}
						onChange={handleChange}
						error={errors.profile_errors.height}
						errorMessage={errors.profile_errors.height ? errors.profile_errors.height[0] : ''}
					/>

					{/* Weight */}
					<InputFieldDark
						label="Weight"
						type="number"
						name="weight"
						value={profileData.weight}
						onChange={handleChange}
						error={errors.profile_errors.weight}
						errorMessage={errors.profile_errors.weight ? errors.profile_errors.weight[0] : ''}
					/>

					{/* Address */}
					<InputFieldDark
						label="Address"
						type="text"
						name="address"
						value={profileData.address}
						onChange={handleChange}
						error={errors.profile_errors.address}
						errorMessage={errors.profile_errors.address ? errors.profile_errors.address[0] : ''}
					/>

					{/* Postcode */}
					<InputFieldDark
						label="Postcode"
						type="text"
						name="postcode"
						value={profileData.postcode}
						onChange={handleChange}
						error={errors.profile_errors.postcode}
						errorMessage={errors.profile_errors.postcode ? errors.profile_errors.postcode[0] : ''}
					/>

					{/* Town */}
					<InputFieldDark
						label="Town"
						type="text"
						name="town"
						value={profileData.town}
						onChange={handleChange}
						error={errors.profile_errors.town}
						errorMessage={errors.profile_errors.town ? errors.profile_errors.town[0] : ''}
					/>

					<SelectFieldDark
						label="Country"
						name="country"
						value={profileData.country}
						onChange={handleChange}
						options={countries.map((country) => ({
							value: country.code,
							label: country.name
						}))}  // Pass your options here
						error={errors.profile_errors.country}
						errorMessage={errors.profile_errors.country ? errors.profile_errors.country[0] : ''}
					/>

				</div>


				<div className="flex justify-end mt-6 space-x-4">
					<ActionButton
						onClick={handleSave}
						label={loading ? <LoadingSpinner /> : 'Save'}
						disabled={loading}
					/>

					<ActionButton
						onClick={() => navigate('/user-profile')}
						label="Cancel"
						customStyles="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md"
					/>

				</div>
			</div>
		</div>
	);
}

export default EditProfile;
