import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import axiosInstance from '../axiosInstance';

import FolderModal from './FolderModal';
import Modal from './Modal';
import { formatDistanceToNow } from 'date-fns';
import VideoItem from './home/VideoItem';
import FolderItem from './home/FolderItem';


function MediaExplorer({ folders, videos, onViewFolder, setFolders, setVideos }) {
    const navigate = useNavigate();
    const isFolderEmpty = folders.length === 0 && videos.length === 0;

    const [activeVideoMenu, setActiveVideoMenu] = useState(null);  // State to manage which video menu is open
    const [activeFolderMenu, setActiveFolderMenu] = useState(null);  // State to manage which folder menu is open


	const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
	const [folderToRename, setFolderToRename] = useState(null); // Track the folder to be renamed


    const toggleVideoMenu = (id) => {
        setActiveVideoMenu((prevActiveMenu) => (prevActiveMenu === id ? null : id)); // Toggle video menu visibility
    };

    const toggleFolderMenu = (id) => {
        setActiveFolderMenu((prevActiveMenu) => (prevActiveMenu === id ? null : id)); // Toggle folder menu visibility
    };

	const handleCreateOrRenameFolder = async (id, folderName) => {
		if (id) {
			// Rename folder
			try {
				await axiosInstance.put(`/api/folders/${id}/`, { name: folderName });
				onRenameSuccess(id, folderName);
			} catch (error) {
				console.error('Error renaming folder:', error);
			}
		}
	};

	// Handle rename success and update state
	const onRenameSuccess = (id, newName) => {
		setFolders((prevFolders) =>
			prevFolders.map((folder) =>
				folder.id === id ? { ...folder, name: newName } : folder
			)
		);
		closeModal();
	};


	// Close the modal and reset the folderToRename state
	const closeModal = () => {
		setIsFolderModalOpen(false);
		setFolderToRename(null);
	};


    // Function to handle menu item clicks for both videos and folders
    const handleMenuItemClick = (action, id, type, event) => {
        event.stopPropagation();  // Prevent event from bubbling up to parent elements
        console.log(`Action: ${action}, ID: ${id}, Type: ${type}`);
        if (action === 'move_to_trash') {
            // Call appropriate delete handler based on type (video or folder)
            if (type === 'video') {
                handleDeleteVideo(id);
            } else if (type === 'folder') {
                handleDeleteFolder(id);
            }
        } else if (action === 'rename' && type === 'folder') {
			// Handle rename
			const folder = folders.find(f => f.id === id); // Find the folder to rename
			setFolderToRename(folder);  // Set the folder to rename
			setIsFolderModalOpen(true); // Open the modal
		}
        setActiveVideoMenu(null);  // Close the dropdown menu after an action
        setActiveFolderMenu(null);  // Close the dropdown menu after an action
    };

    // Function to delete a video
    const handleDeleteVideo = async (id) => {
        try {
            await axiosInstance.delete(`/api/videos/${id}/delete/`);

            // Remove deleted video from state
            onDeleteSuccess('video', id);
        } catch (error) {
            console.error('Error deleting video:', error);
        }
    };

    // Function to delete a folder
    const handleDeleteFolder = async (id) => {
        try {
            await axiosInstance.delete(`/api/folders/${id}/delete/`);  // Ensure you have the correct endpoint

            // Remove deleted folder from state
            onDeleteSuccess('folder', id);
        } catch (error) {
            console.error('Error deleting folder:', error);
        }
    };

    // Function to handle success after deleting a video or folder
    const onDeleteSuccess = (type, deletedId) => {
        if (type === 'video') {
            setVideos(prevVideos => prevVideos.filter(video => video.id !== deletedId));
        } else if (type === 'folder') {
            setFolders(prevFolders => prevFolders.filter(folder => folder.id !== deletedId));
        }
    };
    // Filter personal folders and runner folders
    const personalFolders = folders.filter(folder => !folder.runner);  // Folders without runner
    const runnerFolders = folders.filter(folder => folder.runner);

    return (
        <div className="">
        {isFolderEmpty ? (
            <div className="text-center text-gray-500 col-span-full w-full">
                Folder is empty
            </div>
        ) : (
        <>
            {videos.length > 0 && (
                <div className="flex flex-wrap gap-4 mt-5">
                    {videos.map((video) => (
                        <VideoItem
                            key={video.id}
                            video={video}
                            activeVideoMenu={activeVideoMenu}
                            toggleVideoMenu={toggleVideoMenu}
                            handleMenuItemClick={handleMenuItemClick}
                        />
                    ))}
                </div>
            )}

            {personalFolders.length > 0 && (
                <div className="mt-8">
                    <div className="flex flex-wrap gap-4 mt-5">
                        {personalFolders.map((folder) => (
                            <FolderItem
                                key={folder.id}
                                folder={folder}
                                activeFolderMenu={activeFolderMenu}
                                toggleFolderMenu={toggleFolderMenu}
                                handleMenuItemClick={handleMenuItemClick}
                                onViewFolder={onViewFolder}
                            />
                        ))}
                    </div>
                </div>
            )}

            {/* Display runner folders */}
            {runnerFolders.length > 0 && (
                <div className="mt-8">
                    <p className="text-xl font-semibold text-white">My Runners</p>
                    <div className="flex flex-wrap gap-4 mt-5">
                        {runnerFolders.map((folder) => (
                            <FolderItem
                                key={folder.id}
                                folder={folder}
                                activeFolderMenu={activeFolderMenu}
                                toggleFolderMenu={toggleFolderMenu}
                                handleMenuItemClick={handleMenuItemClick}
                                onViewFolder={onViewFolder}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    )}
    <Modal isOpen={isFolderModalOpen} onClose={closeModal}>
        <FolderModal onCreate={handleCreateOrRenameFolder} onClose={closeModal} folder={folderToRename} />
    </Modal>
</div>

    );
}

export default MediaExplorer;
