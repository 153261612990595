import React, { useState, useContext } from 'react';
import axiosInstance from '../axiosInstance'; // Use axiosInstance to handle requests
import { AppContext } from '../AppContext';
import { BASE_URL } from '../config';
import ActionButton from './utils/ActionButton';

import LoadingSpinner from './utils/LoadingSpinner';

function Settings() {
    const { profile, logout, setProfile } = useContext(AppContext); // Get profile from context
    const [language, setLanguage] = useState(profile.language_preference || 'en');
    const [heightUnit, setHeightUnit] = useState(profile.height_unit || 'cm');
    const [weightUnit, setWeightUnit] = useState(profile.weight_unit || 'kg');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // Manage delete account confirmation
    const [loading, setLoading] = useState(false);

    const handleSaveSettings = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.put(
                `${BASE_URL}/api/profile/update-settings/`, // Assuming you have an endpoint for updating settings
                {
                    language_preference: language,
                    height_unit: heightUnit,
                    weight_unit: weightUnit
                }
            );

            if (response.status === 200) {
                // Update the profile in the local storage and state after successful API call
                const updatedProfile = {
                    ...profile,
                    language_preference: language,
                    height_unit: heightUnit,
                    weight_unit: weightUnit
                };

                // Update profile in the context state
                setProfile(updatedProfile);

                // Update profile in local storage
                localStorage.setItem('profile', JSON.stringify(updatedProfile));

                alert('Settings updated successfully');
            } else {
                alert('Failed to update settings');
            }
        } catch (error) {
            console.error('Error updating settings', error);
            alert('An error occurred while updating settings');
        } finally {
            setLoading(false); // Stop loading
        }
    };


    // Function to delete user account
    const handleDeleteAccount = async () => {
        if (!showDeleteConfirm) {
            setShowDeleteConfirm(true); // Show confirmation
            return;
        }

        try {
            const response = await axiosInstance.delete(`${BASE_URL}/api/delete-account/`);

            if (response.status === 200) {
                alert('Account deleted successfully');
                logout(); // Clear data and redirect to login
            } else {
                alert('Failed to delete account');
            }
        } catch (error) {
            console.error('Error deleting account', error);
            alert('An error occurred while deleting your account');
        }
    };

    const cancelDeleteAccount = () => {
        setShowDeleteConfirm(false); // Cancel delete account
    };

    return (
        <div className="p-6 bg-[#2D2D2D] shadow-lg rounded-md">
            <h2 className="text-2xl font-semibold text-white mb-6">Settings</h2>
            <div className="space-y-6">
                {/* Language Preference */}
                <div>
                    <label className="text-gray-400 text-sm mb-2 block">Language Preference</label>
                    <select
                        className="w-full px-4 py-2 border border-gray-600 bg-[#2D2D2D] text-white rounded-sm focus:outline-none focus:ring-2 focus:ring-[#4caf50]"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                    >
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                        <option value="fr">French</option>
                    </select>
                </div>

                {/* Height Unit */}
                <div>
                    <label className="text-gray-400 text-sm mb-2 block">Height Unit</label>
                    <select
                        className="w-full px-4 py-2 border border-gray-600 bg-[#2D2D2D] text-white rounded-sm focus:outline-none focus:ring-2 focus:ring-[#4caf50]"
                        value={heightUnit}
                        onChange={(e) => setHeightUnit(e.target.value)}
                    >
                        <option value="cm">Centimeters</option>
                        <option value="in">Inches</option>
                    </select>
                </div>

                {/* Weight Unit */}
                <div>
                    <label className="text-gray-400 text-sm mb-2 block">Weight Unit</label>
                    <select
                        className="w-full px-4 py-2 border border-gray-600 bg-[#2D2D2D] text-white rounded-sm focus:outline-none focus:ring-2 focus:ring-[#4caf50]"
                        value={weightUnit}
                        onChange={(e) => setWeightUnit(e.target.value)}
                    >
                        <option value="kg">Kilograms</option>
                        <option value="lbs">Pounds</option>
                    </select>
                </div>

                <div className="flex justify-end space-x-4 mt-8">

                    <ActionButton
                        onClick={handleSaveSettings}
                        label={loading ? <LoadingSpinner /> : 'Save Settings'}
                        customStyles={`bg-[#4caf50] hover:bg-green-600 text-white flex items-center justify-center ${loading ? 'cursor-not-allowed' : ''}`}
                        disabled={loading}
                    />


                    {showDeleteConfirm ? (
                        <div className="flex items-center space-x-4 bg-[#202020] text-white p-4 rounded-md">
                            <p>Are you sure you want to delete your account?</p>
                            <div className="flex space-x-4">
                            <ActionButton
                                onClick={cancelDeleteAccount}
                                label="Cancel"
                            />

                            <ActionButton
                                onClick={handleDeleteAccount}
                                label="Confirm"
                                customStyles="bg-red-600 hover:bg-red-700 text-white"
                            />

                            </div>
                        </div>
                    ) : (
                        <ActionButton
                            onClick={handleDeleteAccount}
                            label="Delete Account"
                            customStyles="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md"
                        />

                    )}
                </div>


            </div>
        </div>
    );
}

export default Settings;
