import React from 'react';

const VideoContent = ({isSmallScreen, videoSrc, videoRef, canvasRef, playbackSpeed, handleSpeedChange, StepsChart }) => {
  return (
		<div className={`flex-1 w-full ${isSmallScreen ? 'ml-12': 'ml-0'} ${isSmallScreen ? 'mr-[4.7rem]': 'mr-0'}`}>
			<div className="px-5 mt-5">
				<div className="card">
					<div className="w-full h-full flex flex-col justify-center items-center overflow-hidden">
						{videoSrc ? (
						<video
							ref={videoRef}
							controls
							src={videoSrc}
							className='w-auto h-[480px] max-w-full'
							onPlay={() => {
							if (videoRef.current) {
								videoRef.current.playbackRate = playbackSpeed; // Set playback rate when video plays
							}
							}}
						>
							<source src={videoSrc} type="video/mp4" />
							Your browser does not support this video format.
						</video>
						) : (
						<p className="text-white">Loading video...</p>
						)}
						<canvas ref={canvasRef} width="800" height="480" />
					</div>
				</div>
				<div className="card mt-4">
					<div className="mb-5">
						<i className="fa fa-chart-line me-2"></i>
						<span>Graphs</span>
					</div>
					<div style={{ width: '100%', height: 300 }}>
						<StepsChart />
					</div>
				</div>
			</div>
		</div>
  	);
};

export default VideoContent;
