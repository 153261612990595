import React from 'react';
import rightIcon from '../../static/ar-right.svg';
import runIcon from '../../static/run.png';
import { FRONTEND_BASE_URL } from '../../config';


const VideoAnalysisStats = ({ isVisible, toggleRightSidebar, accordionItems, handleToggleAccordion, tabs, activeTab, handleTabChange, isSmallScreen }) => {
  	return (
		<div
			className={`${
				isVisible
				? isSmallScreen
					? 'absolute w-96 z-40 right-0 h-full' // Similar behavior as the left sidebar
					: 'w-3/12'
				: isSmallScreen
				? 'absolute w-15 z-40 right-0 h-full' // Adjust collapsed width on small screens
				: 'w-0.5/12'
			} transition-all duration-300`}
			>
			<div className="menu_item menu_right">
				<div className="flex justify-between mb-5 pt-2.5">
				<div className="left">
					<img
					className={`h-6 w-6 head_icon cursor-pointer transform transition-transform duration-300 ${isVisible ? '' : 'rotate-180'}`}
					src={`${FRONTEND_BASE_URL}${rightIcon}`}
					alt="right arrow icon"
					onClick={toggleRightSidebar}
					/>
				</div>
				{isVisible && (
					<div className="title_right text-white">Analysis</div>
				)}
				</div>

				{/* Accordion logic */}
				<div className="accordion">
				{accordionItems.map((item, index          ) => (
					<div className="accordion-in mt-2" key={`accor_${index}`}>
					<div className="flex justify-between items-center bg-[#2D2D2D] p-4 mb-2 rounded-md">
						{/* Always show percentage indicator */}
						<div className="r-auto p-1 flex items-center">
						<div className={`progress ${item.status === 'Bad' ? 'red' : ''}`}>
							<span className="progress-left">
							<span className={`progress-bar ${item.status === 'Bad' ? 'ten' : ''}`}></span>
							</span>
							<span className="progress-right">
							<span className={`progress-bar ${item.status === 'Bad' ? 'ten' : ''}`}></span>
							</span>
							<div className="progress-value">{item.percentage}%</div>
						</div>

						{/* Make text closer to the percentage indicator */}
						{isVisible && (
							<div className="flex items-center ml-2">
							<span className="text-white">{item.name}</span>
							</div>
						)}
						</div>

						{/* Show name, status, and arrow only if sidebar is visible */}
						{isVisible && (
						<div className="flex items-center space-x-2">
							<div className="accordion-header flex items-center cursor-pointer" onClick={() => handleToggleAccordion(item.id)}>
							<span className={`pBadge badge rounded-full ${item.status === 'Bad' ? 'bg-red' : 'bg-green'} w-16 h-6 flex items-center justify-center text-white`}>
								{item.status}
							</span>
							<svg className={`w-5 h-5 ${item.isOpen ? 'rotate-180' : ''} ml-2`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"></path>
							</svg>
							</div>
						</div>
						)}
					</div>

					{/* Accordion content */}
					{isVisible && item.isOpen && (
						<div className="p-4 accordion-body mt-2 rounded-md">
						<div>
							<img className="tab_head_img" src={`${FRONTEND_BASE_URL}${runIcon}`} alt="img" />
						</div>
						<div className="block p-5">
							<ul className="acc_tab mt-2 nav nav-tabs flex" role="tablist">
							{tabs.map((tab, index) => (
								<li className="nav-item" key={`tab_${index}`}>
								<button
									onClick={() => handleTabChange(index)}
									className={`nav-link ${activeTab === index ? 'active' : ''}`}
									type="button"
								>
									{tab.name}
								</button>
								</li>
							))}
							</ul>

							<div className="tab-content">
							<div className="fade tab-pane active show">
								<div>
								<div className="mt-3">
									<h6 className="tab_heading">Current Position</h6>
									<p className="">Sub head</p>
									<p className="card-text">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
								</div>
								</div>
							</div>
							<div className="fade tab-pane">
								<div>This tab's content is never seen</div>
							</div>
							</div>
						</div>
						</div>
					)}
					</div>
				))}
				</div>
			</div>
		</div>
  	);
};

export default VideoAnalysisStats;
